import {Speaker, SpeakerInSpeechDAO} from './Speaker';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export interface SpeechDAO {
    id: string;
    childMarkdownRemark: {
        html: string
        fields: {
            slug: string;
        };
        frontmatter: {
            title: string;
            startDateTime: string;
            endDateTime: string;
            speaker: SpeakerInSpeechDAO[];
        };
    };
}

class SpeechDate {
    order: number;
    date: string;
    time: string;

    constructor(speechDateString: string) {
        const speechDate = speechDateString.split('|');
        this.order = parseInt(speechDate[0]);
        this.date = speechDate[1];
        this.time = speechDate[2];
    }
}

export class Speech {
    private readonly _id: string;
    private readonly _relativePath: string;
    private readonly _order: number;
    private readonly _startDate: string;
    private readonly _startTime: string;
    private readonly _endDate: string;
    private readonly _endTime: string;
    private readonly _timezone: string;
    private readonly _title: string;
    private _html: string;
    private readonly _speaker: Speaker[];


    constructor(id: string, relativePath: string, order: number, startDate: string, startTime: string,
                endDate: string, endTime: string, timezone: string, title: string, speaker: Speaker[], html: string) {
        this._id = id;
        this._relativePath = relativePath;
        this._order = order;
        this._startDate = startDate;
        this._startTime = startTime;
        this._endDate = endDate;
        this._endTime = endTime;
        this._timezone = timezone;
        this._title = title;
        this._html = html;
        this._speaker = speaker;
    }

    static deserialize(data: SpeechDAO, timezone: string): Speech {
        console.log(data);
        const id = data.id;
        const relativePath = data.childMarkdownRemark.fields.slug;
        const html = data.childMarkdownRemark.html;
        const frontmatter = data.childMarkdownRemark.frontmatter;
        const startDate = new SpeechDate(frontmatter.startDateTime);
        const endDate = new SpeechDate(frontmatter.endDateTime);
        const title = frontmatter.title;
        let speaker: Speaker[] = [];
        if (frontmatter.speaker && frontmatter.speaker.length > 0) {
            speaker = frontmatter?.speaker?.filter(speaker => !!speaker)?.map(Speaker.deserializeSpeakerInSpeech);
        }

        return new Speech(id, relativePath, startDate.order, startDate.date, startDate.time, endDate.date, endDate.time, timezone, title, speaker, html);
    }

    get id(): string {
        return this._id;
    }

    get relativePath(): string {
        return this._relativePath;
    }

    get order(): number {
        return this._order;
    }

    get startDate(): string {
        return this._startDate;
    }

    get startTime(): string {
        return this._startTime;
    }

    get endDate(): string {
        return this._endDate;
    }

    get endTime(): string {
        return this._endTime;
    }

    get timezone(): string {
        return this._timezone;
    }
    get title(): string {
        return this._title;
    }

    get html(): string {
        return this._html;
    }

    set html(_html :string) {
        this._html = _html;
    }

    get speakers(): Speaker[] {
        return this._speaker;
    }
}
