import * as React from 'react';
import SEO, {SEOProps} from '../components/SEO';
import {PropsWithChildren} from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import {graphql, useStaticQuery} from "gatsby";
import FooterMenu, {menuType} from "../components/utils/footerMenu";
import {IGatsbyImageData} from "gatsby-plugin-image";
import {filterMenu} from "../utils/Predicates";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GeneralNavbarProps {
    seo?: SEOProps,
    location: string
}

interface GeneralNavbarQuery {
    commons: {
        logo: IGatsbyImageData,
        home: string,
        menu: menuType[]
    },
    hero: {
        logo: IGatsbyImageData,
        image: IGatsbyImageData,
        title: string,
        location: string,
        date: string,
    }
}

const IndiaBasePageLayout: React.FC<PropsWithChildren<GeneralNavbarProps>> = ({children, location, seo}) => {
    const data: GeneralNavbarQuery = useStaticQuery(componentQuery);

    return (
        <React.Fragment>
            <SEO {...seo}/>
            <div className="soul-font-scale-m">
                <Header navbar={{...data.commons, location: location}} hero={{...data.hero, className: 'esteco-hero--india'}}/>
                <main className="soul-space-stack-top-3-xl">
                    {children}
                </main>
                <Footer>
                    <div className="soul-label soul-label--reduced soul-heading--h6 h-text-bold">UM23 India</div>
                    <nav className="soul-space-stack-top-m">
                        <FooterMenu menu={filterMenu(data.commons.menu)}/>
                    </nav>
                </Footer>
            </div>
        </React.Fragment>
    );
}

const componentQuery = graphql`
    query indiaQuery {
      commons: indiaJson(jsonData: {eq: "commons"}) {
        logo {
          childImageSharp {
            gatsbyImageData(width: 120)
          }
        }
        home
        menu {
          enabled
          label
          type
          url
          wideOnMobile
        }
      }
      hero: indiaJson(jsonData: {eq: "hero"}) {
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        location
        date
      }
    }
`;

export default IndiaBasePageLayout;
