import {Speech, SpeechDAO} from './Speech';
import {IGatsbyImageData} from "gatsby-plugin-image";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export interface SpeakerInSpeechDAO {
    id: string;
    relativePath: string;
    childMarkdownRemark: {
        fields: {
            slug: string;
        };
        frontmatter: {
            firstName: string;
            lastName: string;
            jobTitle: string;
            organization: string;
            avatar: IGatsbyImageData;
        };
    };
}

export interface SingleSpeakerQueryDAO {
    markdownRemark: {
        html: string
        frontmatter: {
            firstName: string;
            lastName: string;
            jobTitle: string;
            avatar: IGatsbyImageData;
            organization: string;
            speeches: SpeechDAO[]
        }
    };
}

export class Speaker {
    private readonly _id: string;
    private readonly _relativePath: string;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _jobTitle: string;
    private readonly _organization: string;
    private readonly _html: string;
    private readonly _avatar: IGatsbyImageData;
    private readonly _speeches: Speech[];


    constructor(id: string, relativePath: string, firstName: string,
                lastName: string, jobTitle: string, organization: string, html: string,
                avatar: IGatsbyImageData, speech: Speech[]) {
        this._id = id;
        this._relativePath = relativePath;
        this._firstName = firstName;
        this._lastName = lastName;
        this._jobTitle = jobTitle;
        this._organization = organization;
        this._html = html;
        this._avatar = avatar;
        this._speeches = speech;
    }

    static deserializeSpeakerInSpeech(data: SpeakerInSpeechDAO): Speaker {
        const id = data.id;
        const relativePath = data.childMarkdownRemark.fields.slug;
        const frontmatter = data.childMarkdownRemark.frontmatter;
        const firstName = frontmatter.firstName;
        const lastName = frontmatter.lastName;
        const jobTitle = frontmatter.jobTitle;
        const organization = frontmatter.organization;
        const html = '';
        const avatar = frontmatter.avatar;
        const speeches: Speech[] = [];

        return new Speaker(id, relativePath, firstName, lastName, jobTitle,
            organization, html, avatar, speeches);
    }

    static deserializeSingleSpeakerQuery(data: SingleSpeakerQueryDAO, timezone: string): Speaker {
        const frontmatter = data.markdownRemark.frontmatter;
        const id = '';
        const relativePath = '';
        const firstName = frontmatter.firstName;
        const lastName = frontmatter.lastName;
        const jobTitle = frontmatter.jobTitle;
        const organization = frontmatter.organization;
        const html = data.markdownRemark.html;
        const avatar = frontmatter.avatar;
        let speeches: Speech[] = [];
        if (frontmatter.speeches) {
            speeches = frontmatter.speeches.map(s => Speech.deserialize(s, timezone));
        }

        return new Speaker(id, relativePath, firstName, lastName, jobTitle,
            organization, html, avatar, speeches);
    }

    get fullName(): string {
        return `${this._firstName} ${this._lastName}`;
    }

    get id(): string {
        return this._id;
    }

    get relativePath(): string {
        return this._relativePath;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    get organization(): string {
        return this._organization;
    }

    get html(): string {
        return this._html;
    }

    get avatar(): IGatsbyImageData {
        return this._avatar;
    }

    get speeches(): Speech[] {
        return this._speeches;
    }
}
