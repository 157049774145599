import * as React from 'react';
import {Speaker} from '../../models/Speaker';
import {Speech} from '../../models/Speech';
import SLink from '../soul/link/SLink';
import {AnchorType} from '../soul/link/LinkTypes';
import {predicates} from '../../utils/Predicates';
import Grid from '../soul/grid/Grid';
import {GridDirection} from '../soul/grid/GridDirection';
import {GridDensity} from '../soul/grid/GridDensity';
import {GVAlignment} from '../soul/grid/GridAlignment';
import GridItem from '../soul/grid/GridItem';
import {GridBreakpoint} from '../soul/grid/GridBreakpoint';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
type SpeechFlagProps = {
    speechesList: Speech[];
    hideDateAndTime?: boolean;
}

const SpeechesListByDay: React.FC<SpeechFlagProps> = ({speechesList, hideDateAndTime}) => {
    const getSpeakersNames = (speech: Speech) => speech.speakers
        .map((one: Speaker, i) =>
            <React.Fragment key={i}>
                <SLink to={one.relativePath} linkType={AnchorType.GHOST}>{one.fullName}</SLink>
                {i !== speech.speakers.length - 1 ? <> &middot; </> : ''}
            </React.Fragment>
        );
    const getSpeakersCompanies = (speech: Speech) => [...new Set(speech.speakers.map((one: Speaker) => one.organization))]
        .map((one: string, i, a) => <React.Fragment key={i}>{one}{i !== a.length - 1 ? <> &middot; </> : ''}</React.Fragment>)
    const speechInfo = (speech: Speech) => {
        let cardContent;

        if (predicates.isNotEmpty(speech.speakers) || !!speech.html) {
            let speakersContent;
            if (predicates.isNotEmpty(speech.speakers)) {
                speakersContent = <>
                    <span className="soul-label soul-label--strong soul-label--inline">{getSpeakersNames(speech)}</span>&nbsp;&middot;&nbsp;
                    <span className="soul-label soul-label--reduced soul-label--inline">{getSpeakersCompanies(speech)}</span>
                </>;
            }
            cardContent =
                <div className="soul-navigation-card__description soul-font-scale-s">
                    {speakersContent}
                    <div dangerouslySetInnerHTML={{__html: speech.html}}></div>
                </div>;
        }

        return (
        <>
            <div className="soul-navigation-card__title">
                <span className="soul-heading soul-heading--h4 soul-font-scale-m">{speech.title}</span>
            </div>
            {cardContent}
        </>);
    }

    return (
        <>
            {speechesList.map((speech: Speech, index) => (
                <div className="soul-navigation-card soul-navigation-card--comfortable" key={index}>
                    <div className="soul-navigation-card__content">
                        <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.REGULAR} wrap={false} vAlignment={GVAlignment.CENTER}>
                            {hideDateAndTime
                                ? ''
                                : <GridItem colSpan={3} responsive={[
                                    {
                                        size: 4,
                                        breakpoint: [GridBreakpoint.M, GridBreakpoint.S, GridBreakpoint.XS]
                                    }
                                ]}>
                                <div className="esteco-wrap">
                                    <span className="esteco-nowrap">{speech.startTime}&nbsp;-&nbsp;</span><span className="esteco-nowrap">{speech.endTime}</span>
                                    <span className="esteco-nowrap"><span className="soul-font-size-xs soul-label--reduced">&nbsp;{speech.timezone}</span></span>
                                </div>
                            </GridItem>}
                            <GridItem colSpan={9}>
                                {speechInfo(speech)}
                            </GridItem>
                        </Grid>
                    </div>
                </div>
            ))}
        </>
    );
};

export default SpeechesListByDay;
