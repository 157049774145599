import * as React from 'react';
import SpeechesListByDay from './SpeechesListByDay';
import {getNameFromDate} from '../../utils/StringUtils';
import {Section} from '../Section';
import Grid from '../soul/grid/Grid';
import {GridDirection} from '../soul/grid/GridDirection';
import {GHAlignment, GVAlignment} from '../soul/grid/GridAlignment';
import GridItem from '../soul/grid/GridItem';
import {ColSize} from '../estecoColumn/EstecoColumnsSizes';
import {PropsWithChildren} from 'react';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
type SpeechesListProps = {
    startDateEventsMap: any[]
    hideDateAndTime?: boolean
}
const EventDaysList: React.FC<PropsWithChildren<SpeechesListProps>> = ({startDateEventsMap, children, hideDateAndTime}) => {
    return (
        <>
            {startDateEventsMap
                .map((entry, index) => {
                    const formattedStartDate = entry[0];
                    const events = entry[1];

                    return (
                        <Section key={index}>
                            <Grid direction={GridDirection.HORIZONTAL} vAlignment={GVAlignment.CENTER} hAlignment={GHAlignment.SPACE_BETWEEN}>
                                <GridItem colSpan={0}>
                                    <h2 className="soul-heading soul-heading--h2 soul-font-size-xl soul-text-indicator-primary">
                                        {startDateEventsMap.length > 1
                                            ? `Day ${getNameFromDate(formattedStartDate)}: ${formattedStartDate}`
                                            : formattedStartDate}
                                    </h2>
                                </GridItem>
                                {children
                                    ? <GridItem colSpan={0}>
                                        {children}
                                    </GridItem>
                                    : ''}
                            </Grid>
                            <div className="soul-space-stack-top-m">
                                <div className="soul-navigation-card-list soul-navigation-card-list--connected soul-navigation-card--not-clickable">
                                    <SpeechesListByDay speechesList={events} hideDateAndTime={hideDateAndTime}></SpeechesListByDay>
                                </div>
                            </div>
                        </Section>
                    );
                })
            }
        </>
    );
}

export default EventDaysList;
