/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const NUMBER_NAMES = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven',
    'Eight', 'Nine', 'Ten'
];

const DAY_INDEXES = {
    '23 Aug 2023': 'One',
    '24 Oct 2023': 'One',
    '25 Oct 2023': 'Two',
}

export const getNameFromNumber = (num: number): string => NUMBER_NAMES[num];
export const getNameFromDate = (date: string): string => DAY_INDEXES[date as keyof typeof DAY_INDEXES];
